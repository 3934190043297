<template>
  <v-card>
    <v-card-title v-if="title" id="header" class="primary white--text">
      <h4 class="ml-1s">{{ title }}</h4>
    </v-card-title>
    <v-card-text>
        <span v-for="(link, index) in links" :key="link.label" @click="click(index)">
          <v-row class="mt-4">
            <v-col class="col-auto">
              <p class="text-left">
                <v-icon :color="link.color">{{ link.icon }}</v-icon>
              </p>
            </v-col>
            <v-col>
              <h3 :class="link.textcolor">{{ link.label }}</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-divider
              class="ma-1"
              v-if="index < links.length - 1"
            ></v-divider>
          </v-row>
        </span>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'LinksCard',

    data: () => ({
      title: "Links",
      links: [
          { 
            label: "Instagram",
            url: "https://www.instagram.com/dan_olsn/",
            icon: 'mdi-instagram',
            color: 'pink darken-1',
            textcolor: 'pink--text'
          },
          { 
            label: "Venmo",
            url: "https://www.venmo.com/DanOlsen/",
            icon: 'mdi-currency-usd',
            color: 'blue darken-1',
            textcolor: 'blue--text'
          },
          { 
            label: "Facebook",
            url: "https://www.facebook.com/DanOlsen/",
            icon: 'mdi-facebook',
            color: 'indigo darken-1',
            textcolor: 'indigo--text'
          }
      ],
    }),
    methods: {
      click (index) {
        window.open(this.links[index].url, "_blank");
      },
    }
  }
</script>
