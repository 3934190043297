<template>
  <v-card>
    <v-sheet
      color="teal darken-4"
      height="200px"
    >
      <v-container fill-height fluid>
        <v-row align="center"
            justify="center">
            <v-col>
              <h1 class="text-center white--text">
                Dan Olsen
              </h1>
            </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-card-text>
      <v-row align="center"
        justify="center">
        <v-col class="col-auto">
          <p class="text-left">
            <v-icon color="grey" class="mt-3">mdi-map-marker</v-icon>
          </p>
        </v-col>
        <v-col>
          <h3 color="grey" class="text-left">
            Chicago, IL
          </h3>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'HeaderCard',

    data: () => ({
      
    }),
  }
</script>
