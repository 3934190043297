<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <HeaderCard/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <LinksCard/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2family=Poppins:wght@400;700&display=swap');
  $body-font-family: 'Poppins', sans-serif;
  $title-font: 'Poppins', sans-serif;

  .application {
    font-family: "Questrial";
  }
  .v-application {
   font-family: $body-font-family, sans-serif !important;
    .title {
      font-family: $title-font, sans-serif !important;
    }
  }
  #app {
    background: url('https://images.unsplash.com/photo-1563718944-758794a56b34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1275&q=80') no-repeat center center fixed !important;
    background-size: cover;
  }
</style>

<script>
import HeaderCard from './components/HeaderCard.vue';
import LinksCard from './components/LinksCard.vue';

export default {
  name: 'App',

  components: {
    HeaderCard,
    LinksCard
  },

  data: () => ({
    //
  }),
};
</script>
